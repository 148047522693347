<template>
  <v-row class="cv-listed mt-6">
    <v-col v-if="objectIsNotEmpty && isCvData" class="col-12 cv-listed__listed-container">
      <div class="cv-listed__section">
        <template v-for="({ title, data }, index) in blockData">
          <BlockInfo :title="title" :data="data" :key="index"/>
        </template>
      </div>
    </v-col>
    <v-col v-else class="col-12 cv-listed__no-data">
      {{ $t("CV.NO_DATA") }}
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import BlockInfo from '@/components/BlockInfo';

export default {
  name: 'CVListed',
  components: {
    BlockInfo,
  },
  computed: {
    ...mapGetters({
      cv: 'cv/getCV',
      personalData: 'cv/getPersonalData',
      educationData: 'cv/getEducationData',
      experienceData: 'cv/getExperienceData',
      skillsData: 'cv/getSkillsData',
    }),
    objectIsNotEmpty() {
      return Object.keys(this.cv).length !== 0;
    },

    isCvData() {
      return this.personalData.length ||
             this.educationData.length ||
             this.experienceData.length ||
             this.skillsData;
    },

    blockData() {
      return [
        { title: 'Personal', data: this.personalData },
        { title: 'Education', data: this.educationData },
        { title: 'Experience', data: this.experienceData },
        { title: 'Skills', data: this.skillsData },
      ];
    },
  },
};
</script>

<style lang="scss">
$titleOfSectionWidth: 300px;

.cv-listed {
  width: 95%;
  margin: 0 auto;
  overflow: scroll;
}

.cv-listed__button-back {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cv-listed__section {
  max-width: 800px;
  margin: 0 auto 30px;
}

.cv-listed__block {
  border-bottom: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;

  .cv-listed__data-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: auto;
    max-height: 3000px;
    transition: all 0.6s ease;

    &:last-child {
      .dataItem:last-child {
        border-radius: 0 0 4px 4px;
      }
    }
  }
}

.cv-listed__title-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 23px;
  width: 100%;
  border: 1px solid $color-dark-blue;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: $color-white;
  background: $color-dark-blue;
  cursor: pointer;
  text-transform: capitalize;
  transition-property: background-color, color;
  transition-duration: 0.4s;

  &:hover {
    background-color: darken($color-dark-blue, 3%);
  }
}

.cv-listed__title {
  width: 100%;
  overflow: hidden;
  margin-right: 5px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.cv-listed__data-item {
  display: flex;
  padding: 10px 23px;
  width: 100%;
  border: 1px solid $color-dark-blue;
  border-top: none;
  font-size: 20px;
  line-height: 30px;
  background: $color-dark-blue;
  font-weight: 600;
  color: $color-black;
  background: $color-white;
}

.cv-listed__data-item-experience,
.cv-listed__data-item-education {
  &:first-child {
    border-top: 1px solid$color-dark-blue;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.cv-listed__item-key {
  min-width: $titleOfSectionWidth;
  margin-right: 5px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.cv-listed__item-value {
  display: inline-flex;
  white-space: pre-line;
  width: calc(100% - #{$titleOfSectionWidth});
  font-weight: normal;
}

.cv-listed__no-data {
  text-align: center;
  font-size: 36px;
  color: $color-dark-blue;
}
</style>
